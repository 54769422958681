.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: transparent;
  margin-bottom: 16px;
}
.label {
  font-size: 12px;
  background: transparent;
  padding: 0;
  color: #757575;
  font-weight: 400;
}
.message {
  font-size: 12px;
  color: var(--primary-color-red);
  display: block;
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.message svg {
  width: 16px;
  margin-right: 4px;
}
.message svg path {
  fill: var(--primary-color-red);
}
.input {
  height: 36px;
  padding-left: 1rem;
  padding-right: 10px;
  border: none;
  margin-top: 10px;
  background: #f5f5f5;
  border-bottom: 1.5px solid #bdbdbd;
  border-radius: 8px 8px 0px 0px;
}
.hasValue {
  border-color: var(--primary-color-blue);
  background: #eee9ff;
}
.input::placeholder,
.textarea::placeholder {
  color: #757575;
  font-weight: 300;
}
.textarea {
  padding-left: 1rem;
  padding-top: 12px;
  margin-top: 10px;
  border: none;
  border-bottom: 1.5px solid #bdbdbd;
  border-radius: 8px 8px 0px 0px;
  background: #f5f5f5;
  font-size: 14px;
  resize: none;
  height: 150px;
}
.icon {
  position: absolute;
  right: 10px;
  top: 2.2rem;
  width: 20px;
  height: auto;
  cursor: pointer;
}
.error label {
  color: var(--primary-color-red);
}
.error .input, .error textarea {
  background: #fffafa;
  border-bottom: 1.5px solid var(--primary-color-red);
}
