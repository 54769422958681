.container {
  padding: 2rem;
}
.container h1 {
  color: var(--bs-body-color);
  margin-top: 2rem;
  font-size: 20px;
  font-weight: 600;
}
.container p {
  white-space: break-spaces;
  margin-top: 1rem;
  color: var(--bs-body-color);
  width: 100%;
  overflow: auto;
  padding-bottom: 1rem;
}
.container p::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}
