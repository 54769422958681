.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: transparent;
  margin-bottom: 16px;
}
.label {
  font-size: 11px;
  background: transparent;
  padding: 0;
  color: #757575;
  font-weight: 400;
}
.message {
  font-size: 11px;
  color: var(--primary-color-red);
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.message svg {
  width: 16px;
  margin-right: 4px;
}
.icon {
  position: absolute;
  right: 10px;
  top: 2rem;
  width: 20px;
  height: auto;
  cursor: pointer;
}
.select [class*="formSelect__indicator-separator"] {
  display: none;
}
.select [class*="formSelect__control"] {
  min-height: unset;
  margin-top: 10px;
  background: #f5f5f5;
  border: none;
  border-bottom: 1.5px solid #bdbdbd;
  border-radius: 8px 8px 0px 0px;
  text-transform: capitalize;
}
.select [class*="formSelect__control--is-focused"] {
  border-color: #e5e5e5 !important;
  box-shadow: none;
}
.hasValue [class*="formSelect__control"], .hasValue [class*="formSelect__control--is-focused"] {
  border-color: var(--primary-color-blue) !important;
  background: #eee9ff;
}
.select [class*="formSelect__input-container"] {
  height: 36px;
  margin: 0;
}
.select [class*="formSelect__value-container"] {
  padding: 0 0 0 1rem;
}
.select [class*="formSelect__placeholder"] {
  color: var(--grey);
  opacity: 0.8;
  font-size: 13px;
}
.error label {
  color: var(--primary-color-red);
}
.error .select [class*="formSelect__control"] {
  background: #fffafa;
  border-bottom: 1.5px solid var(--primary-color-red);
}
.select [class*="formSelect__menu"] {
  text-transform: capitalize;
}
