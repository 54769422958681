.container {
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
}
.backBtn {
  background: transparent;
  border: none;
  padding: 0;
}
.backBtn span {
  color: var(--primary-color-blue);
  text-decoration: underline;
  font-weight: 500;
  margin-left: 8px;
}
.backBtn .svg {
  width: 22px;
}
.ttl {
  color: var(--primary-color-blue);
  text-decoration: underline;
  font-weight: 500;
  margin-left: 8px;
  text-align: center;
  font-size: 16px;
  margin-top: 2rem;
}

/* Nav */
/* Form redesign */
.ctrlSec {
  display: flex;
  justify-content: space-between;
  color: #121212;
  font-weight: 500;
  flex-direction: column;
  padding: 1.2rem 1rem 1.2rem 1.2rem;
  width: 180px;
  position: fixed;
  transform: translateY(-50%);
  top: 50%;
  background: #fafbfc;
  border-radius: 8px;
  font-size: 16px;
}
.ctrlSec div {
  border-left: 2px solid transparent;
  margin-bottom: 1.2rem;
  padding-left: 10px;
}
.ctrlSec div:last-of-type {
  margin: 0;
}
.ctrlSec .ctrlActive {
  border-color: var(--primary-color-blue);
  color: var(--primary-color-blue);
  font-weight: 600;
}

.formWrap,
.subFormWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.formTtl {
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-size: 18px;
  font-weight: 500;
  color: #101323;
}
.inputWrap {
  width: 32%;
}
.nextBtn {
  font-size: 14px;
  height: 44px;
  width: 120px;
  display: block;
  background: var(--primary-color-blue);
  color: #fff;
  border: none;
  border-radius: 8px;
  margin-top: 3rem;
  margin-left: auto;
}

.avatarSec {
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  width: 100%;
  flex-wrap: wrap;
}
.avatar {
  border-radius: 50%;
  object-fit: cover;
  padding: 2px;
  object-position: top;
  width: 60px;
  height: 60px;
  justify-content: center;
  margin-right: 1.3rem;
  background: #f5f5f5;
  padding: 4px;
}

.changeBtn {
  width: fit-content;
  font-size: 11px;
  border-radius: 6px;
  border: none;
  padding: 9px 18px;
  color: #fff !important;
  background: var(--primary-color-blue);
  margin-right: 8px;
}
.changeBtn input {
  display: none;
}
.errorMsg {
  font-size: 11px;
  color: var(--primary-color-red);
  display: block;
  margin-top: 4px;
  width: 100%;
  text-align: left;
  margin-top: 10px;
}
.errorMsg svg {
  width: 16px;
  margin-right: 4px;
}

.subFormWrap {
  position: relative;
}
.addBtn {
  height: fit-content;
  color: var(--primary-color-blue);
  background: transparent;
  border: none;
  font-weight: 500;
  padding: 6px 0;
  display: flex;
  align-items: center;
  margin-left: auto;
  white-space: nowrap;
}
.addBtn svg {
  border: 1px solid var(--primary-color-blue);
  border-radius: 50%;
  background: var(--primary-color-blue);
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.addBtn svg path {
  fill: #fff;
}
.removeBtn {
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 13px;
  position: absolute;
  top: 2rem;
  right: 0;
  background: none;
}
.addBtn:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.formUploadWrap {
}
.formUploadWrap .formTtl {
  margin-bottom: 1rem;
}
.docsWrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 3rem 2rem;
  margin-bottom: 4rem;
}

/* Doc Upload */
.uploadWrap {
  width: 100%;
}
.uploadLabel {
  font-weight: 500;
  color: #101323;
  margin-bottom: 14px;
}
.uploadWrap label {
  width: 100%;
  border: 1px dashed #8d7ed2;
  border-radius: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}
.uploadTxt {
  margin-bottom: 4px;
  font-weight: 500;
}
.formats {
  color: #8d7ed2;
  font-size: 10px;
  margin: 0;
}
.uploadWrap input {
  display: none;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b3a9e1;
  box-shadow: 0px 0px 0px 4px rgba(166, 97, 255, 0.16);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  min-width: 24px;
  margin-bottom: 14px;
}
.icon svg {
  width: 60%;
}
.uploadSuccess {
}

.trashIcon {
  padding: 0 2px;
  width: 21px;
  cursor: pointer;
}
.uploadSuccess .uploadLabelWrap {
  display: flex;
  background: #f5f5f5;
  border-radius: 12px;
  width: 100%;
  display: flex;
  padding: 1.7rem 1.5rem;
  align-items: flex-start;
}
.uploadSuccess .uploadLabelWrap > svg {
  min-width: 18px;
}
.uploadSuccess .formFileUploadLabel {
  text-align: left;
  background: transparent;
  padding: 0 16px;
  /* margin-top: -5px; */
  width: 100%;
}
.uploadSuccess .formFileUploadLabel p:first-child {
  color: #424242;
}
.uploadSuccess .formFileUploadLabel p:nth-child(2) {
  font-size: 11px;
  color: #757575;
}
.uploadProgress {
  width: 100%;
  height: 4px;
  background: #e0e0e0;
  border-radius: 4px;
  margin: 1rem 0;
  position: relative;
}
.uploadProgress::before {
  content: "";
  display: block;
  height: 100%;
  background: var(--primary-color-blue);
  width: 10%;
  border-radius: inherit;
  animation: progress 1.5s ease-in;
  animation-fill-mode: forwards;
}
.disabledCtrl {
  opacity: 0.5;
  cursor: not-allowed;
}
@keyframes progress {
  0% {
    width: 10%;
  }
  50% {
    width: 30%;
  }
  100% {
    width: 100%;
  }
}

.refTtlWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.refTtlWrap .formTtl {
  margin: 0;
}

/* Prospect plan */
.closeBtn {
  width: fit-content;
  font-size: 36px;
  line-height: 1;
  color: var(--primary-color-red);
  background: transparent;
  border: none;
  margin-left: auto;
  margin-top: -3rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
}
.planContainer {
  display: flex;
  flex-direction: column;
  background: #fafbfc;
  border-top-left-radius: 48px;
  border-top-right-radius: 48px;
  position: fixed;
  bottom: 0;
  height: 90%;
  box-shadow: 0px -1px 3px -1px rgb(0 0 0 / 16%);
  width: 100vw;
  padding-top: 4rem;
  padding-bottom: 2rem;
  overflow: auto;
  z-index: 1;
  animation: slideUp 1.5s ease-out;
}
@keyframes slideUp {
  0% {
    bottom: -100vh;
  }
  100% {
    bottom: 0;
  }
}
.planInfoWrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
}
.pricingSection {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0px;
  max-width: 900px;
  padding: 4rem 1rem;
  color: #323b4b;
}
.priceTxtSec {
  margin-bottom: 3rem;
}
.priceTtl1 {
  font-size: 14px;
  color: #121212;
  font-weight: 600;
}
.priceTtl2 {
  color: var(--primary-color-blue);
  margin-bottom: 3rem;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  width: 100%;
  padding-top: 4px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.priceTtl2 img {
  width: 72px;
  height: 72px;
  min-width: 72px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 8px;
}
.priceTxt {
  font-size: 16px;
  margin-bottom: 4rem;
  text-align: center;
  width: 100%;
}
.priceCardWrap {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}
.priceCard {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
  color: #323b4b;
  justify-content: space-between;
  align-items: flex-start;
  background: #fff;
  box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 16%);
  border-radius: 10px;
  cursor: pointer;
}
.priceCardActive {
  box-shadow: 0px 0px 0px 1px rgb(57 19 210);
}
.priceCard > div {
  width: 100%;
  padding: 0 1rem;
}
.priceCard input {
  margin-top: 4px;
}
.priceCardInfo {
  padding-right: 1rem;
}
.priceCardTtl {
  font-weight: 600;
  margin-bottom: 6px;
}
.priceCardTxt1 {
  font-weight: 600;
  color: #3913d2;
}
.priceCardTxt1 span {
  font-size: 16px;
  font-weight: 400;
  padding-left: 4px;
  color: #323b4b;
}
.priceCardTxt2 {
  margin: 0;
}
.priceSubTtl {
  font-size: 16px;
  color: #323b4b;
}
.services {
  color: #323b4b;
  list-style: none;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  font-size: 13px;
}
.services li::before {
  content: "";
  background: url(../../assets/vectors/bi_star-fill.svg);
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  margin-top: 5px;
}
.services li {
  padding-left: 0;
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
}
.services li b {
  padding: 0 4px;
}
.priceCardLink {
  width: 100%;
  display: block;
  text-align: center;
  background: #3913d2;
  color: #fff;
  max-width: 300px;
  border-radius: 6px;
  padding: 11px 1rem;
  margin: auto;
  font-size: 15px;
}
.priceCardLink:hover {
  color: #fff;
}
.morePriceSec {
  width: 100%;
  text-align: center;
  margin-top: 4rem;
  background: #fff;
  padding: 2rem 1rem;
  border-radius: 12px;
}
.morePriceSec .priceCardTxt2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 4px;
}
.priceCardTxt3 {
  font-size: 15px;
  margin-bottom: 1.5rem;
}
.priceTxtSecTxt {
  font-size: 16px;
  margin-bottom: 2rem;
  line-height: 1.7;
  font-weight: 600;
}
.note {
  color: #121212;
  margin-top: 3rem;
  font-weight: 400;
}
.note a {
  text-decoration: underline !important;
  color: var(--primary-color-blue);
  padding-left: 4px;
}
.continueBtn {
  background: var(--primary-color-blue);
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 1rem;
  width: 39%;
  margin: auto;
  margin-top: 2rem;
  margin-right: 8.5%;
}
.formContainer {
  margin-left: 240px;
}
.formHeading {
  color: #121212;
  font-size: calc(1.2rem + 1.5vw);
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.poweredBy {
  text-align: center;
  display: block;
  margin-bottom: -1rem;
  margin-top: 2rem;
}
.poweredBy .logo {
  width: 60px;
}
@media screen and (max-width: 1000px) {
  .inputWrap {
    width: 48%;
  }
}
@media screen and (max-width: 800px) {
  .continueBtn {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .closeBtn {
    position: absolute;
    top: 1.2rem;
    margin: 0;
    right: 2rem;
  }
  .planContainer {
    padding-top: 3rem;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }
  .ctrlSec {
    position: unset;
    margin-top: 2rem;
    margin-bottom: 1rem;
    flex-direction: row;
    width: 100%;
    padding: 0;
    background: transparent;
    font-size: 14px;
    transform: unset;
  }
  .ctrlSec div {
    border-left: none;
    border-bottom: 3px solid #fafbfc;
    width: 25%;
    text-align: center;
    padding-bottom: 10px;
  }
  .formContainer {
    margin: 0;
  }
  .formHeading {
    margin-top: 0;
  }
  .ctrlSec div:last-of-type {
    margin-bottom: inherit;
  }
}
@media screen and (max-width: 550px) {
  .inputWrap {
    width: 100%;
  }
  .refTtlWrap .addBtn {
    font-size: 13px;
  }
  .refTtlWrap .addBtn svg {
    width: 16px;
    height: 16px;
  }
  .planInfoWrap {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  .planContainer {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
  .closeBtn {
    top: 10px;
    right: 10px;
  }
  .priceTtl2 {
    font-size: 24px;
    margin-bottom: 2rem;
    line-height: 28px;
  }
  .priceTtl2 img {
    width: 60px;
    height: 60px;
    min-width: 60px;
  }
}
